import React from 'react';
import { useParams } from 'react-router-dom';
import AllScoresComponentForClient from './AllScoresComponentForClient';
import TopBarForClientView from '../ui/TopBarForClientView';
import ExportAllScoresButton from './ExportAllScoresButton';

import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const ClientPage = () => {
  const { eventId } = useParams();

  const logGAEvent = () => {
    ReactGA.event({
      category: `Client page category ${eventId}`,
      action: `Client page viewed ${eventId}`,
      label: `Client page label ${eventId}`,
    });
  };

  useEffect(() => {
    logGAEvent();
  }, []);

  return (
    <div className='client-page-container'>
      <TopBarForClientView />
      <div className='my-width-700'>
        <AllScoresComponentForClient eventId={eventId} />
        {/* TODO: hide that from here, make it update scores */}
        <ExportAllScoresButton eventId={eventId} />
      </div>
    </div>
  );
};

export default ClientPage;
