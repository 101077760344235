import React from 'react';
import TopBarForStartNowPage from '../../components/ui/TopBarForStartNowPage';

const StartNow = () => {
  return (
    <div className='w-full overflow-hidden'>
      <TopBarForStartNowPage />
      <div className='w-max-7xl'>
        <iframe
          title='Create your event with InstaJudge'
          aria-label='Create your event with InstaJudge'
          frameborder='0'
          style={{ height: '1500px', width: '99%', border: 'none' }}
          src='https://forms.zohopublic.com/scigentechnologiessa/form/CreateyoureventwithInstaJudge2/formperma/Hek6Z49uquFwhnsAL214_LZLOLE0ZTx_mGQkOcSJ8ew'
        />
      </div>
    </div>
  );
};

export default StartNow;
