import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import PosterJudgementCard from './PosterJudgementCard';
import { TextField } from '@mui/material';
import GoToLeaderboardButton from './GoToLeaderboardButton';
import TopBarForJudgeViewPage from '../ui/TopBarForJudgeViewPage';
import ReactGA from 'react-ga4';
import { useEventContext } from '../../context/EventContext';

const JudgeView = () => {
  const { judgeId, groupId } = useParams();
  const { setActiveEventId } = useEventContext();

  const [judgeName, setJudgeName] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventId, setEventId] = useState('');
  const [description, setDescription] = useState('');
  const [scoringQuestions, setScoringQuestions] = useState([]);
  const [scoresOfJudge, setScoresOfJudge] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState([]);

  const logGAEvent = () => {
    console.log('Someone landed on judge page!');
    ReactGA.event({
      category: `Judge page category ${judgeId}`,
      action: `Judge page viewed ${judgeId}`,
      label: `Judge page label ${judgeId}`,
    });
  };

  useEffect(() => {
    logGAEvent();
  }, []);
  // TODO: remove that? GC
  const [sortMode, setSortMode] = useState('default');

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getJudgeView = async () => {
    try {
      const { data } = await axios.get(`/api/judges/get-judge-view/${judgeId}/${groupId}`);

      setJudgeName(data.judge.name);
      setEventName(data.judge.event.name);
      setEventId(data.judge.event._id);
      setActiveEventId(data.judge.event._id);
      setDescription(data.judge.event.description);
      setScoringQuestions(data.judge.event.scoringQuestions);
      setScoresOfJudge(data.scoresOfJudge.sort((a, b) => a.poster.index - b.poster.index));
      setLabels(data.judge.event.labels);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getJudgeView();
  }, []);

  const filterBySearchText = (score) => {
    return (
      score.poster.title.toLowerCase().includes(searchText.toLowerCase()) ||
      score.poster.presenterName.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const addAnswerToScore = (data) => {
    const newScoresOfJudge = scoresOfJudge.map((score) => {
      if (score._id === data._id) {
        return { ...score, answers: [...data.answers] };
      }
      return score;
    });

    console.log('New scores of change after operation', newScoresOfJudge);

    setScoresOfJudge(newScoresOfJudge);
  };

  const completed = scoresOfJudge.filter((s) => s.answers.length > 0).length;
  const total = scoresOfJudge.length;

  useEffect(() => {
    if (completed === total) {
      // Scroll to the top of the page when completed === total
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [completed, total]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='judge-view-main-container'>
      <TopBarForJudgeViewPage eventName={eventName} eventId={eventId} />
      <div
        style={{
          padding: '1em',
          borderRadius: '1em 1em 0 0',
          paddingBottom: '10em',
          width: '100%',
          maxWidth: '700px',
        }}
      >
        {/* {completed === total && (
          <div className='padding1 margin-block-1 row success-message'>
            You are done scoring. <CheckmarkIcon />
          </div>
        )} */}
        <div className='scoring-card shadowed margin-block-1'>
          <div className='font-montserrat font-bold text-[28px] text-center text-mint-deep-green opacity-100'>{eventName}</div>
          <div className='font-montserrat font-semibold text-[16px] text-center text-[#828274] opacity-100'>{description}</div>
          <div className='font-montserrat font-semibold text-[16px] text-center text-[#828274] opacity-100'>Welcome, {judgeName}</div>
        </div>
        {/* <div className='scoring-card shadowed margin-block-1'>
        <p style={{ fontSize: '0.8rem' }}>
            Completed: {completed} / {total}
          </p>
        <TextField
            id='search'
            search='search'
            label='Search'
            type='text'
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            size='small'
            fullWidth
          />
        <ScoringProgressBar completed={completed} total={total} /> */}
        {/* </div> */}
        {/* <div>
          <FormControl component='fieldset'>
            <h3 component='legend'>Show {labels.poster.singular} scores</h3>
            <RadioGroup
              aria-label='Show poster scores'
              name='show-poster-scores'
              value={
                sortMode === 'alphabetically' ? 'alphabetically' : sortMode === 'score' ? 'score' : sortMode === 'byGroup' ? 'byGroup' : 'default'
              }
              onChange={(event) => setSortMode(event.target.value)}
            >
              <div className='row'>
                <FormControlLabel value={'default'} control={<Radio />} label='Default' />
                <FormControlLabel value={'alphabetically'} control={<Radio />} label='Alphabetically' />
                {completed.length > 0 && <FormControlLabel value={'score'} control={<Radio />} label='Not Scored first' />}
                <FormControlLabel value={'byGroup'} control={<Radio />} label={`By ${labels.group.singular}`} />
              </div>
            </RadioGroup>
          </FormControl>
        </div> */}
        <div className='col'>
          {scoresOfJudge
            .filter((s) => filterBySearchText(s))
            .sort((a, b) => {
              if (sortMode === 'alphabetically') {
                if (a.poster.title > b.poster.title) {
                  return 1;
                } else {
                  return -1;
                }
              } else if (sortMode === 'score') {
                if (a.answers.length > 0) {
                  return 1;
                } else {
                  return -1;
                }
              } else if (sortMode === 'byGroup') {
                if (a.poster.group.name > b.poster.group.name) {
                  return 1;
                } else {
                  return -1;
                }
              } else {
                return 0;
              }
            })
            .map((score, index) => (
              <div key={score._id}>
                <PosterJudgementCard
                  index={index}
                  poster={score.poster}
                  scoringQuestions={scoringQuestions}
                  judgeId={judgeId}
                  onAddScore={addAnswerToScore}
                  score={score}
                  scoringQueestionsLabel={labels.scoringQuestions.plural}
                />
              </div>
            ))}
          {isMobile && (
            <div className='flex justify-center'>
              <GoToLeaderboardButton eventId={eventId} variant='contained' fullWidth={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JudgeView;
