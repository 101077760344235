import { Button } from '@mui/material';
import React, { useState } from 'react';
import { read, utils } from 'xlsx';

const ExcelToJsonComponent = ({ setPostersToBeImported }) => {
  const formatJson = (json) => {
    console.log(json);
    setPostersToBeImported(json);
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    try {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(worksheet);

          formatJson(json);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ display: 'grid', placeItems: 'center' }}>
      <form>
        <label
          htmlFor='upload'
          className='MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium css-17bdk83-MuiButtonBase-root-MuiButton-root'
        >
          {/* <Button variant='contained' htmlFor='upload'> */}
          Import list
          {/* </Button> */}
        </label>
        <input
          type='file'
          name='upload'
          id='upload'
          // onClick={readUploadFile}
          onChange={readUploadFile}
          style={{ display: 'none' }}
        />
      </form>
    </div>
  );
};

export default ExcelToJsonComponent;
