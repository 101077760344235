import React from 'react';
import image from './assets/1.jpg';
import image2 from './assets/2.jpg';
import TopBarForIndividualCustomerStories from '../../../components/ui/TopBarForIndividualCustomerStories';
import { Link } from 'react-router-dom';

const EICC2024AudienceEngaged = () => {
  return (
    <div className='container-with-bg'>
      <TopBarForIndividualCustomerStories />
      <div className='max-w-4xl mx-auto my-5 p-4 sm:px-6 lg:px-8 hero-section-glass-container'>
        {/* Title */}
        <h2 className='text-3xl font-bold text-center mb-5 text-mint-deep-green'>EICC 2024 - Audience Engaged</h2>
        {/* Content */}
        <p className='mb-3'>
          The European Interdisciplinary Cybersecurity Conference (EICC) 2024 aims to establish a venue for the exchange of information on
          cybersecurity and its many aspects between academics and practitioners in Europe. Until 2019 EICC was known as the Central European
          Cybersecurity Conference (CECC) and has since been rebranded to underscore the interdisciplinarity of the conference and broaden its reach.
        </p>
        <p className='mb-3'>
          EICC 2024 encourages dialogue between computer scientists and researchers in other fields related to cybersecurity such as behavioral
          sciences, sociology, criminology, investigations and law.
        </p>
        <p className='mb-3'>
          The EICC 2024 conference took place in Xanthi, Greece, on June 5-6. In one session they had the highest rated papers submitted to the
          conference. InstaJudge was utilized to engage the audience and include everyone listening to the presentation in the judging process. At the
          end of the final presentation, a QR code was projected onto the main screen and all attendees were invited to share their opinions, using
          their phones to submit a score. More than 200 votes were submitted by the audience using the intuitive InstaJudge interface.
        </p>
        <p className='mb-3'>Judges had immediate access to the results and used them to select the winners.</p>
        <img src={image} alt='image 1' className=' w-full object-cover mb-3' />
        <img src={image2} alt='image 2' className=' w-full object-cover mb-3' />
        <div className='mb-3 w-full flex justify-center'>
          <Link to={'/customer-stories'} className='text-mint-deep-green hover:underline'>
            Go back to stories
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EICC2024AudienceEngaged;
