// import { CheckIcon } from '@heroicons/react/20/solid'

import { CheckmarkIcon } from 'react-hot-toast';
import Blog from './screens/customer-stories/CustomerStories';
import { Link } from 'react-router-dom';

const includedFeatures = ['Live audience feedback', 'Instant reports', 'Premium support', 'Cloud - based infrastructure'];

export default function PricingSection() {
  return (
    <div className='bg-white py-6 sm:py-6'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl sm:text-center'>
          {/* <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>A</h2> */}
          {/* <p className='mt-6 text-lg leading-8 text-gray-600'>
            Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.
          </p> */}
        </div>
        <div className='mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none'>
          <div className='p-8 sm:p-10 lg:flex-auto'>
            <h3 className='text-2xl font-bold tracking-tight text-gray-900'>Fair and Square Pricing</h3>
            <p className='mt-6 text-base leading-7 text-gray-600'>
              InstaJudge is designed for competitions big and small. Whether you’re planning a cozy get-together or a large-scale symposium,
              InstaJudge is the simple and affordable solution.
            </p>
            <div className='mt-10 flex items-center gap-x-4'>
              <h4 className='flex-none text-sm font-semibold leading-6 text-mint-light-green'>What’s included</h4>
              <div className='h-px flex-auto bg-gray-100' />
            </div>
            <ul role='list' className='mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6'>
              {includedFeatures.map((feature) => (
                <li key={feature} className='flex gap-x-3'>
                  {/* <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" /> */}
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className='sm:flex sm:flex-col sm:gap-2 md:flex md:flex-row gap-2 -mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0'>
            <div className='rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16'>
              <div className='mx-auto max-w-xs px-8'>
                <p className='text-base font-semibold text-gray-600'>Fair start</p>
                <p className='mt-6 flex items-baseline justify-center gap-x-2'>
                  <span className='text-5xl font-bold tracking-tight text-gray-900'>Free</span>
                  <span className='text-sm font-semibold leading-6 tracking-wide text-gray-600'></span>
                </p>
                <Link
                  to={'/start-now'}
                  className='mt-10 block w-full rounded-md bg-mint-light-green px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-mint-deep-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                >
                  Start now
                </Link>
                <p className='mt-6 text-xs leading-5 text-gray-600 min-w-[148px]'>Open judging</p>
                <p className='mt-6 text-xs leading-5 text-gray-600 min-w-[148px]'>Up to 50 participants</p>
              </div>
            </div>
            <div className='rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16'>
              <div className='mx-auto max-w-xs px-8'>
                <p className='text-base font-semibold text-gray-600'>Square deal</p>
                <p className='mt-6 flex items-baseline justify-center gap-x-2'>
                  <span className='text-5xl font-bold tracking-tight text-gray-900'>$299</span>
                  <span className='text-sm font-semibold leading-6 tracking-wide text-gray-600'>USD</span>
                </p>
                <a
                  href='mailto:sales@scigentech.com?subject=InstaJudge: Quote Request'
                  className='mt-10 block w-full rounded-md bg-mint-light-green px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-mint-deep-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                >
                  Contact sales
                </a>
                <p className='mt-6 text-xs leading-5 text-gray-600 min-w-[148px]'>Different judging modes</p>
                <p className='mt-6 text-xs leading-5 text-gray-600 min-w-[148px]'>Unlimited participants</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
