import React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import LoadingButton from '@mui/lab/LoadingButton';

const CreatePoster = ({ eventId, onSuccess = () => console.log('No on success was passed!'), closeModal }) => {
  const [posterIndex, setPosterIndex] = useState('');
  const [posterTitle, setPosterTitle] = useState('');
  const [presenterName, setPresenterName] = useState('');
  const [presenterEmail, setPresenterEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post(`/api/posters/create/${eventId}`, {
        index: posterIndex.trim(),
        title: posterTitle.trim(),
        presenterName: presenterName.trim(),
        presenterEmail: presenterEmail.trim(),
      });
      setSuccess(data.message);
      setLoading(false);
      setPosterTitle('');
      onSuccess();
      closeModal();

      //   setSuccess(data.message);
      toast.success('Poster created successfully');
    } catch (error) {
      console.log(error.response.data.error_message);
      toast.error(error.response.data.error_message);
      //   setError(error.response.data.error_message);
      setLoading(false);
    }
  };
  return (
    <div className='padding1 margin1'>
      <form onSubmit={submitHandler}>
        <Grid container spacing={3} direction='column' textAlign={'center'}>
          <h1>Create Poster</h1>
          <Grid item>
            <TextField
              id='index'
              name='index'
              label='Poster Index'
              type='number'
              value={posterIndex}
              required
              fullWidth
              onChange={(e) => setPosterIndex(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              id='title'
              name='title'
              label='Poster Title'
              type='text'
              value={posterTitle}
              required
              fullWidth
              onChange={(e) => setPosterTitle(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              id='presenter-name'
              name='presenter-name'
              label='Presenter Name'
              type='text'
              value={presenterName}
              // required
              fullWidth
              onChange={(e) => setPresenterName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              id='presenter-email'
              name='presenter-email'
              label='Presenter Email'
              type='email'
              value={presenterEmail}
              // required
              fullWidth
              onChange={(e) => setPresenterEmail(e.target.value)}
            />
          </Grid>
          <Grid item>
            {error && <p className='error-message'>{error}</p>}
            {success && <p className='success-message'>{success}</p>}
          </Grid>
          <Grid item className='buttons-container'>
            <Button onClick={closeModal}>Cancel</Button>
            <LoadingButton
              loading={loading}
              type='submit'
              variant='contained'
              disabled={posterTitle.trim().length === 0 || presenterName.trim().length === 0 || presenterEmail.trim().length === 0}
            >
              Create poster
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreatePoster;
