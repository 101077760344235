import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { Button, Grid, TextField } from '@mui/material';

const CreateJudge = ({ eventId, onSuccess, closeModal }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const clearState = () => {
    setName('');
    setEmail('');
    setError('');
    setSuccess('');
    setLoading(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post(`/api/judges/create/${eventId}`, { name: name.trim(), email: email.trim() });
      setSuccess(data.message);
      setLoading(false);
      toast.success('Judge created successfully');
      onSuccess();
      closeModal();
    } catch (error) {
      console.log(error.response.data.error_message);
      toast.error(error.response.data.error_message);
      setError(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div className='padding1'>
      {error && <p>{error}</p>}
      {success && <p>{success}</p>}
      <form onSubmit={submitHandler}>
        <Grid container spacing={3} direction='column' textAlign={'center'}>
          <h1>Create Judge</h1>
          <Grid item>
            <TextField id='name' name='name' label='name' type='text' value={name} required fullWidth onChange={(e) => setName(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField
              id='email'
              email='email'
              label='email'
              type='email'
              value={email}
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item className='buttons-container'>
            <Button onClick={closeModal}>Cancel</Button>
            <LoadingButton loading={loading} type='submit' variant='contained' disabled={name.trim().length === 0 || email.trim().length === 0}>
              Create Judge
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreateJudge;
