import { LoadingButton } from '@mui/lab';
import { Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useEventContext } from '../../context/EventContext';

const SettingsShowGroupNames = ({ eventId }) => {
  const { showGroupNames: showGroupNamesFromContext, setShowGroupNames: setShowGroupNamesFromContext } = useEventContext();

  const [showGroupNames, setShowGroupNames] = useState(showGroupNamesFromContext);

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const updateShowGroupNames = async () => {
    setIsRequestLoading(true);
    try {
      const { data } = await axios.put(`/api/events/show-group-names/${eventId}`, { showGroupNames });
      toast.success('Group names visibility updated successfully');
      setShowGroupNamesFromContext(showGroupNames);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  const isButtonDisabled = showGroupNames === showGroupNamesFromContext;

  return (
    <div className='flex p-4 justify-center items-center'>
      <FormGroup>
        <FormControlLabel
          sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}
          label={'Display group names on judging view ' + (showGroupNames ? '(Now visible)' : '(Now hidden)')}
          control={<Checkbox checked={showGroupNames} onChange={(e) => setShowGroupNames(e.target.checked)} />}
        />
      </FormGroup>
      <div>
        <LoadingButton
          disabled={isButtonDisabled}
          loading={isRequestLoading}
          variant='contained'
          color='primary'
          onClick={updateShowGroupNames}
          size='small'
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default SettingsShowGroupNames;
