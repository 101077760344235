import { LoadingButton } from '@mui/lab';
import { Chip, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useEventContext } from '../../context/EventContext';

const EventScoringMode = ({ eventId }) => {
  const { scoringMode: scoringModeFromContext, setScoringMode: setScoringModeFromContext } = useEventContext();

  const [scoringMode, setScoringMode] = useState(scoringModeFromContext);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const updateScoringMode = async () => {
    setIsRequestLoading(true);
    try {
      const { data } = await axios.put(`/api/events/scoring-mode/${eventId}`, { scoringMode });
      toast.success('Scoring mode updated successfully');
      setScoringModeFromContext(data.scoringMode);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  const isButtonDisabled = scoringMode === scoringModeFromContext;

  return (
    <Grid container spacing={2} direction='column' textAlign={'center'}>
      <div style={{ padding: '1em' }}>
        <Divider component='div' role='presentation'>
          <Chip
            label={<div className='row'>Scoring mode</div>}
            style={{ marginBlock: '1em', background: 'var(--primary)', minWidth: '150px', color: '#fff' }}
          />
        </Divider>
        <FormControl component='fieldset'>
          <Grid item>
            <FormLabel component='legend'>Choose an option</FormLabel>
            <RadioGroup value={scoringMode} onChange={(e) => setScoringMode(e.target.value)}>
              <FormControlLabel value='anonymous' control={<Radio />} label='Anonymous' />
              <FormControlLabel value='judges' control={<Radio />} label='Judges' />
            </RadioGroup>
          </Grid>

          <Grid item>
            <LoadingButton
              disabled={isButtonDisabled}
              loading={isRequestLoading}
              variant='contained'
              color='primary'
              onClick={updateScoringMode}
              size='small'
            >
              Save
            </LoadingButton>
          </Grid>
        </FormControl>
      </div>
    </Grid>
  );
};

export default EventScoringMode;
