import React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import LoadingButton from '@mui/lab/LoadingButton';

const CreateScoringQuestion = ({ eventId, onSuccess = () => console.log('No on success was passed!'), closeModal }) => {
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`/api/events/add-scoring-question/${eventId}`, {
        question,
      });
      toast.success('Scoring question added');
      onSuccess();
      closeModal();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setLoading(false);
  };

  return (
    <div className='padding1 margin1'>
      <form onSubmit={submitHandler}>
        <Grid container spacing={3} direction='column' textAlign={'center'}>
          <h1>Create Scoring Question</h1>
          <Grid item>
            <TextField
              id='question'
              name='question'
              label='Question'
              type='text'
              value={question}
              required
              fullWidth
              onChange={(e) => setQuestion(e.target.value)}
            />
          </Grid>
          <Grid item className='buttons-container'>
            <Button onClick={closeModal}>Cancel</Button>
            <LoadingButton loading={loading} type='submit' variant='contained' disabled={question.trim().length === 0}>
              Create question
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreateScoringQuestion;
