import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useEventContext } from '../../context/EventContext';

const GoToLeaderboardButton = ({ eventId, variant = 'text', fullWidth }) => {
  const { showGoToLeaderboardButton } = useEventContext();

  if (!showGoToLeaderboardButton) {
    return null;
  }

  return (
    <Link to={`/client-view/${eventId}`} className='inline-block' style={{ width: fullWidth ? '100%' : 'auto' }}>
      <Button variant={variant} color='primary' style={{ width: fullWidth ? '100%' : 'auto' }}>
        Go to leaderboard
      </Button>
    </Link>
  );
};

export default GoToLeaderboardButton;
