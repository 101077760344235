import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export default function JudgesMultipleSelectAutocomplete({ allJudges, existingJudgesOfGroup, judgesToBeAddedToGroup, setJudgesToBeAddedToGroup }) {
  return (
    <Stack spacing={3} sx={{ width: 500 }}>
      <Autocomplete
        multiple
        id='tags-outlined'
        options={allJudges}
        getOptionLabel={(judge) => `${judge.name} (${judge.email})`}
        // defaultValue={[top100Films[13]]}
        value={judgesToBeAddedToGroup}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} label='Add judges to group' placeholder='Judges' />}
        // getOptionDisabled={(option) => disabledItems.includes(option.title)}
        getOptionDisabled={(judge) => existingJudgesOfGroup.some((item) => item.name === judge.name)}
        onChange={(e, newValue) => setJudgesToBeAddedToGroup(newValue)}
      />
    </Stack>
  );
}
