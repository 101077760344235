import React from 'react';
import CreateDemoEventButton from './components/event/CreateDemoEventButton';
import COMP1 from '../src/assets/images/comp1.jpg';
import COMP2 from '../src/assets/images/comp2.jpg';
import { H1 } from './components/ui/HTags';
import TopBarForDemoPage from './components/ui/TopBarForDemoPage';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const DemoScreen = () => {
  const logGAEvent = () => {
    console.log('Someone landed on demo page!');
    ReactGA.event({
      category: 'Demo page category',
      action: 'Demo page viewed',
      label: 'Demo page label',
    });
  };

  useEffect(() => {
    logGAEvent();
  }, []);

  return (
    <div className='container-with-bg'>
      <TopBarForDemoPage />
      <div className='max-w-xl rounded shadow-lg bg-white basic-glass text-center mx-10 mt-5'>
        <div className='px-6 py-4'>
          <div className='font-bold text-xl mb-2 text-mint-deep-green'>How It Works</div>
          <p className='text-[#707070] text-base'>
            We created a couple of demo events so you can test <span className='text-[#828274] font-semibold'>Insta</span>
            <span className='text-[#98d829] font-semibold'>Judge</span>. Select an event and click on the "Create demo event" button to move forward.
          </p>
        </div>
      </div>
      <div className='flex flex-wrap justify-around gap-10 p-10'>
        <div className='max-w-sm rounded overflow-hidden shadow-lg bg-white basic-glass text-center'>
          <img className='w-full' style={{ maxHeight: '200px' }} src={COMP1} alt='Baking competition' />
          <div className='px-6 py-4'>
            <div className='font-bold text-xl mb-2 text-mint-deep-green'>Baking Competition</div>
            <p className='text-[#707070] text-base'>
              Join us for an exciting baking competition where passionate bakers come together to showcase their skills and creativity.
            </p>
          </div>
          <div className='px-6 py-4 flex justify-center'>
            <CreateDemoEventButton competitionNumber={1} />
          </div>
        </div>
        <div className='max-w-sm rounded overflow-hidden shadow-lg bg-white basic-glass  text-center'>
          <img className='w-full' style={{ maxHeight: '200px' }} src={COMP2} alt='Story competition' />
          <div className='px-6 py-4'>
            <div className='font-bold text-xl mb-2 text-mint-deep-green'>Story Competition</div>
            <p className='text-[#707070] text-base'>
              Join us for an exciting story competition where passionate writers come together to showcase their skills and creativity.
            </p>
          </div>

          <div className='px-6 py-4 flex justify-center'>
            <CreateDemoEventButton competitionNumber={2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoScreen;
