import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FlipMove from 'react-flip-move';
import { CheckmarkIcon, ErrorIcon, toast } from 'react-hot-toast';
import { Button, Chip, Divider, Grid, TextField, Tooltip, Typography } from '@mui/material';
import ScoringProgressBar from '../ui/ScoringProgressBar';
import JudgeAccordionForClientView from './JudgeAccordionForClientView';
import io from 'socket.io-client';
import WarningIcon from '@mui/icons-material/Warning';
import ExportGroupWinnersButton from './ExportGroupWinnersButton';
import { useEventContext } from '../../context/EventContext';
import { H2, H3, H4, H5, H6 } from '../ui/HTags';
import JudgeNowComponentWithQR from './JudgeNowComponentWithQR';

const SERVER_URL = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : `${process.env.REACT_APP_LOCAL_SERVER_URL}:4000`;

const AllScoresComponentForClient = ({ eventId }) => {
  const [scores, setScores] = useState([]);
  const [groups, setGroups] = useState([]);
  const [posters, setPosters] = useState([]);
  const [winners, setWinners] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { scoringMode, setActiveEventId } = useEventContext();

  const getClientsView = async () => {
    try {
      const { data } = await axios.get(`/api/events/get-client-view/${eventId}`);
      setScores(data.scores);
      setGroups(data.groups);
      setPosters(data.posters);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setActiveEventId(eventId);
    getClientsView();
  }, []);

  // useEffect to connect to socket.io server
  useEffect(() => {
    const socket = io(SERVER_URL);
    // Listen for the 'connect' event to know when the connection is established
    socket.on('connect', () => {
      console.log('Connected to the server');
    });

    // Listen for the 'connect_error' event to handle connection errors
    socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
    });

    // Listen for the 'scoresUpdated' event and trigger data fetch
    socket.on('scoresUpdated', (data) => {
      const updatedScore = data.updatedScore;
      setScores((prevScores) => {
        const index = prevScores.findIndex((score) => score._id === updatedScore._id);
        if (index === -1) {
          return [...prevScores, updatedScore];
        }
        const newScores = [...prevScores];
        newScores[index] = { ...updatedScore };
        return newScores;
      });
      toast.success('New score!');
    });

    return () => {
      // Disconnect the WebSocket when the component unmounts
      socket.disconnect();
    };
  }, []);

  const getWinnersOfEachGroup = () => {
    const winners = {};

    groups.map((group) => {
      const scoresOfGroup = scores.filter((score) => score.poster.group === group._id);
      const postersOfGroup = scoresOfGroup.map((score) => score.poster);

      const uniquePostersOfGroup = [...new Set(postersOfGroup.map((poster) => poster._id))].map((id) =>
        postersOfGroup.find((poster) => poster._id === id)
      );

      const averageScoreOfEachPosterOfTheGroup = uniquePostersOfGroup.map((poster) => {
        const scoresOfPoster = scoresOfGroup.filter((score) => score.poster._id === poster._id);
        const totalScore = scoresOfPoster.reduce((acc, score) => acc + score.answers.reduce((a, b) => a + b, 0), 0);
        const numberOfAnswers = scoresOfPoster.reduce((acc, score) => acc + score.answers.length, 0);
        const averageScore = numberOfAnswers === 0 ? 0 : totalScore / numberOfAnswers;
        return {
          posterId: poster._id,
          averageScore,
          posterTitle: poster.title,
          posterPresenter: poster.presenterName,
        };
      });

      const maxAverageScore = Math.max(...averageScoreOfEachPosterOfTheGroup.map((poster) => poster.averageScore));

      const postersWithScoreEqualToMaxScore = averageScoreOfEachPosterOfTheGroup.filter((poster) => poster.averageScore === maxAverageScore);

      if (maxAverageScore === 0) {
        winners[group._id] = {
          averageScore: 0,
          posters: [],
        };
      } else {
        winners[group._id] = {
          averageScore: maxAverageScore,
          posters: postersWithScoreEqualToMaxScore,
        };
      }
    });

    setWinners(winners);
  };

  useEffect(() => {
    getWinnersOfEachGroup();
  }, [scores]);

  const getAverageScoreOfPoster = (posterId) => {
    const scoresOfPoster = scores.filter((score) => score.poster._id === posterId && score.answers.length !== 0);
    if (scoresOfPoster.length === 0) return 0;
    const totalScore = scoresOfPoster.reduce((acc, score) => acc + score.answers.reduce((a, b) => a + b, 0), 0);
    const totalAnswers = scoresOfPoster.reduce((acc, score) => acc + score.answers.length, 0);
    const averageScore = totalScore / totalAnswers;
    return +averageScore.toFixed(2);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (scoringMode === 'judges') {
    return (
      <div className='col'>
        {groups.map((group) => (
          <div key={group._id}>
            <div className='client-view-group-container'>
              <Divider component='div' role='presentation'>
                <Chip
                  label={
                    <div className='group-row'>
                      {group.name}{' '}
                      {scores.filter((score) => score.poster.group === group._id && score.answers.length !== 0).length !== 0 &&
                        scores.filter((score) => score.poster.group === group._id && score.answers.length !== 0).length ===
                          scores.filter((score) => score.poster.group === group._id).length && <CheckmarkIcon />}
                    </div>
                  }
                  style={{ marginBlock: '1em', background: 'var(--primary)', minWidth: '150px', color: '#fff' }}
                />
              </Divider>
              <ScoringProgressBar
                completed={scores.filter((score) => score.poster.group === group._id && score.answers.length !== 0).length}
                total={scores.filter((score) => score.poster.group === group._id).length}
              />

              {winners[group._id]?.posters.length === 0 ? (
                <div className='col margin-block-1'>
                  <h4>Winner: -</h4>
                </div>
              ) : winners[group._id]?.posters.length === 1 ? (
                <div className='col margin-block-1'>
                  <h4>Winner:</h4>
                  <ul>
                    <li>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: winners[group._id]?.posters.map((poster) => `${poster?.posterTitle} - (Presenter: ${poster?.posterPresenter})`),
                        }}
                      ></div>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className='col margin-block-1'>
                  <div className='row'>
                    <h4>Winners:</h4>
                    <span style={{ display: 'flex', alignItems: 'flex-end' }}>
                      (there is a tie)
                      <Tooltip title='Tie'>
                        <WarningIcon color='error' />
                      </Tooltip>
                    </span>
                  </div>
                  <div>
                    <ul>
                      {winners[group._id]?.posters.map((poster) => (
                        <li key={poster.posterId}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${poster?.posterTitle} - (Presenter: ${poster?.posterPresenter})`,
                            }}
                          ></div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <div className='col margin-block-1'>
                {group.judges.map((judge) => (
                  <div key={judge._id}>
                    <JudgeAccordionForClientView judge={judge} scores={scores.filter((score) => score.poster.group === group._id)} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <ExportGroupWinnersButton eventId={eventId} winners={winners} groups={groups} />
      </div>
    );
  } else {
    return (
      <div className='col'>
        {groups.map((group) => {
          const groupPosters = posters.filter((poster) => poster.group === group._id);
          const sortedPosters = groupPosters.sort((a, b) => getAverageScoreOfPoster(b._id) - getAverageScoreOfPoster(a._id));

          return (
            <div key={group._id}>
              <Divider component='div' role='presentation'>
                <Chip
                  label={<div className='row'>{group.name}</div>}
                  style={{ marginBlock: '1em', background: 'var(--primary)', minWidth: '150px', color: '#fff' }}
                />
              </Divider>
              <JudgeNowComponentWithQR groupId={group._id} eventId={eventId} />
              <div className='client-view-group-container'>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className='flex flex-col'>
                    <p className='font-bold text-[22px] text-[#828274] '>Winner: </p>
                    <H2>
                      {winners[group._id]?.averageScore === 0 || !winners[group._id]?.posters.length ? (
                        '-'
                      ) : winners[group._id]?.posters.length === 1 ? (
                        <div className='font-bold text-[24px] text-[#87B52D]'>{winners[group._id]?.posters[0]?.posterTitle}</div>
                      ) : (
                        <div className='text-orange-500'>
                          {winners[group._id]?.posters.map((poster) => poster?.posterTitle).join(', ')}
                          <Tooltip title='Tie'>
                            <WarningIcon color='warning' />
                          </Tooltip>
                        </div>
                      )}
                    </H2>
                    <div className='mt-auto'>
                      <p className='font-bold text-[20px] text-[#828274] mt-6'>Live Leaderboard </p>
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <FlipMove>
                    {sortedPosters.map((poster) => (
                      <div key={poster._id} className='client-view-contestant-row-container'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div>
                            <H4>{poster.title}</H4>
                          </div>
                          <div className='ml-auto text-right'>
                            <H6>
                              Votes: {scores.filter((score) => score.poster._id === poster._id && score.answers && score.answers.length > 0).length}
                            </H6>
                          </div>
                        </div>
                        <ScoringProgressBar completed={getAverageScoreOfPoster(poster._id)} total={10} />
                      </div>
                    ))}
                  </FlipMove>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

export default AllScoresComponentForClient;
