import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

function valuetext(value) {
  return `${value} points`;
}

export default function ScoreSlider({ score, indexOfQuestion, setScoreOfQuestion }) {
  const handleChange = (event, value) => {
    setScoreOfQuestion(value, indexOfQuestion);
  };

  // to fix uncontrollable slider
  if (!score) score = 0;
  return (
    // <Box sx={{ width: 300 }}>
    <Box>
      <div className='flex justify-between items-end'>
        <div style={{ fontSize: '0.7rem', fontWeight: '400', textAlign: 'center', textWrap: 'wrap', maxWidth: '3em' }}>Strongly disagree</div>
        <div style={{ fontSize: '0.7rem', fontWeight: '400', textAlign: 'center', textWrap: 'wrap', maxWidth: '3em' }}>Strongly agree</div>
      </div>
      <Slider
        getAriaValueText={valuetext}
        step={1}
        marks={marks}
        // valueLabelDisplay='on'
        value={score}
        max={10}
        onChange={handleChange}
      />
    </Box>
  );
}

export const ScoreSlider5 = ({ score, indexOfQuestion, setScoreOfQuestion, startLabel, endLabel }) => {
  const handleChange = (event, value) => {
    setScoreOfQuestion(value, indexOfQuestion);
  };

  const marks = [
    { value: 0, label: '' }, // Use startLabel prop or default to '0'
    { value: 1, label: '' }, // Use startLabel prop or default to '0'
    { value: 2, label: '' }, // Use startLabel prop or default to '0'
    { value: 3, label: '' }, // Use startLabel prop or default to '0'
    { value: 4, label: '' }, // Use startLabel prop or default to '0'
    { value: 5, label: '' }, // Use endLabel prop or default to '5'
  ];
  // to fix uncontrollable slider
  if (!score) score = 0;
  return (
    // <Box sx={{ width: 300 }}>
    <Box className='mt-8'>
      <Slider getAriaValueText={valuetext} step={1} marks={marks} valueLabelDisplay='on' value={score} max={5} onChange={handleChange} />
    </Box>
  );
};
