import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckmarkIcon } from 'react-hot-toast';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

const JudgeAccordionForClientView = ({ judge, scores }) => {
  const scoresOfJudge = scores.filter((s) => s?.judge?._id === judge?._id);
  const completed = scoresOfJudge.filter((s) => s.answers.length != 0).length;
  const total = scoresOfJudge.length;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className='row'>
          <span>{completed === total ? <CheckmarkIcon /> : <ErrorIcon color='warning' />}</span>
          <Typography>{judge.name}</Typography>
          <span>
            {completed}/{total}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='col'>
          {scores
            .filter((s) => s?.judge?._id === judge?._id)
            .map((score, index) => (
              <div key={score._id} className='row'>
                <div
                  style={{ fontSize: '0.8em', maxWidth: '80%' }}
                  dangerouslySetInnerHTML={{
                    __html: `${index + 1}. ${score.poster.title}`,
                  }}
                ></div>
                <div style={{ marginLeft: 'auto' }}>{score.answers.length != 0 ? <CheckmarkIcon /> : <WarningIcon color='error' />}</div>
                <br />
              </div>
            ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
  {
  }
};

export default JudgeAccordionForClientView;
