import * as XLSX from 'xlsx';

const exportJSONToExcel = (data, fileName) => {
  if (!data || data.length === 0) {
    console.warn('No data to export');
    return;
  }

  const columns = Object.keys(data[0]);
  const wsData = [columns];

  data.forEach((item) => {
    const rowData = columns.map((column) => item[column]);
    wsData.push(rowData);
  });

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(wsData);
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet');

  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export default exportJSONToExcel;
