import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import EventCard from './EventCard';
import AddEventButton from './AddEventButton';
import { H1 } from '../ui/HTags';

const AllEvents = () => {
  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    try {
      const { data } = await axios.get('/api/events');
      setEvents(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <H1>All Events</H1>
      <div className='my-4 flex justify-center'>
        <AddEventButton onSuccess={getEvents} />
      </div>
      <div className='grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
        {events.map((event) => (
          <div key={event._id}>
            <EventCard event={event} onDeleteSuccess={getEvents} />
          </div>
        ))}
      </div>
    </>
  );
};

export default AllEvents;
