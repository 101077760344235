import React, { useState, useEffect } from 'react';
import PosterRow from '../poster/PosterRow';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import AddPosterButton from './AddPosterButton';
import { Link } from 'react-router-dom';
import { Button, TextField, Pagination } from '@mui/material';
import GridLoader from '../ui/GridLoader';

const AllPosters = ({ eventId }) => {
  const [posters, setPosters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getPostersOfEvent = async () => {
    try {
      const { data } = await axios.get(`/api/posters/get-posters-of-event/${eventId}`);
      setPosters(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPostersOfEvent();
  }, []);

  if (isLoading) {
    return <GridLoader />;
  }

  const filterByTitle = (poster) => poster.title.toLowerCase().includes(searchText.toLowerCase());
  const filterByPresenterName = (poster) => poster.presenterName.toLowerCase().includes(searchText.toLowerCase());

  const filteredPosters = posters.filter((poster) => filterByTitle(poster) || filterByPresenterName(poster));
  const totalPages = Math.ceil(filteredPosters.length / itemsPerPage);
  const paginatedPosters = filteredPosters.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      {posters.length === 0 && <p>No posters yet</p>}
      <div className='row'>
        <h2>Posters ({posters.length})</h2>
        <div className='actions-container'>
          <Link to={`/events/import/${eventId}`}>
            <Button color='success' size='small'>
              Import posters to event
            </Button>
          </Link>
          <AddPosterButton eventId={eventId} onSuccess={getPostersOfEvent} />
        </div>
      </div>
      <TextField
        id='search'
        search='search'
        label='Search by title | presenter '
        type='text'
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        size='small'
        fullWidth
      />
      <div className='col'>
        {paginatedPosters.map((poster) => (
          <div key={poster._id} className='row'>
            <PosterRow poster={poster} onSuccessfulDelete={getPostersOfEvent} />
          </div>
        ))}
      </div>
      <div className='pagination-container'>
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color='primary' />
      </div>
    </>
  );
};

export default AllPosters;
