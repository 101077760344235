import React from 'react';
import image from './assets/1.jpg';
import image2 from './assets/2.jpg';
import image3 from './assets/3.jpg';

import TopBarForIndividualCustomerStories from '../../../components/ui/TopBarForIndividualCustomerStories';
import { Link } from 'react-router-dom';

const LolitaTasteTestingGelato = () => {
  return (
    <div className='container-with-bg'>
      <TopBarForIndividualCustomerStories />
      <div className='max-w-4xl mx-auto my-5 p-4 sm:px-6 lg:px-8 hero-section-glass-container'>
        {/* Title */}
        <h2 className='text-3xl font-bold text-center mb-5 text-mint-deep-green'>The Best Flavor goes to…</h2>
        {/* Content */}
        <img
          src={image}
          alt='image 1'
          className='w-full object-cover mb-3'
          style={{ maxWidth: '100%', maxWidth: '300px', height: 'auto', margin: 'auto' }}
        />{' '}
        <p className='my-5'>
          Lolita’s Gelateria lives on the picturesque cliffs of Santorini, Greece. This summer, on July 20 and 21, they hosted a special Chocolate
          Event where they debuted new flavors. Over the course of two hot summer evenings, 19 flavors of gelato were taste tested by 70 people who
          submitted 161 votes. Using the InstaJudge platform, patrons scanned the QR code displayed throughout the store and were able to leave
          ratings on each flavor.
        </p>
        <img
          src={image2}
          alt='image 2'
          className=' w-full object-cover mb-3'
          style={{ maxWidth: '100%', maxHeight: '500px', width: 'auto', height: 'auto', margin: 'auto' }}
        />
        <p className='my-5'>
          <strong>Which were the highest-rated flavors?</strong>
          <ul className='px-5'>
            <li>Illanka-Peru (10/10 - 4 votes)</li>
            <li>Milky Choco Caramel (9.88/10 - 8 votes)</li>
            <li>Morogoro Tanzania (9.86/10 - 7 votes)</li>
            <li>Choco Banana (9.83/10 - 6 votes)</li>
            <li>Araguani - Venezuela (9.83/10 - 6 votes)</li>
          </ul>

          <strong>Which were the most popular flavors?</strong>
          <ul className='px-5'>
            <li>Dark Chocolate Vinsanto (9.41/10 - 17 votes)</li>
            <li>White Chocolate Pistachio (9.38/10 - 16 votes)</li>
            <li>Dark Choco Rum (8.67/10 - 12 votes)</li>
            <li>Choco Coconut (9.82/10 - 11 votes)</li>
            <li>White Choco Macademia Caramel (9.8/10 - 10 votes)</li>
          </ul>
        </p>
        <img
          src={image3}
          alt='image 3'
          className=' w-full object-cover mb-3'
          style={{ maxWidth: '100%', maxWidth: '300px', height: 'auto', margin: 'auto' }}
        />
        <p className='my-5'>
          Lolita’s is already planning something big for next summer, so stay tuned for updates–especially if you’re planning a trip to Oia.{' '}
        </p>
        <div className='mb-3 w-full flex justify-center'>
          <Link to={'/customer-stories'} className='text-mint-deep-green hover:underline'>
            Go back to stories
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LolitaTasteTestingGelato;
