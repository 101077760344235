import React, { useEffect, useState } from 'react';
import { Form, Link, useParams, useNavigate } from 'react-router-dom';
import axios, { all } from 'axios';
import toast from 'react-hot-toast';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const EditPoster = () => {
  const [posterIsLoading, setPosterIsLoading] = useState(true);
  const [posterIndex, setPosterIndex] = useState('');
  const [title, setTitle] = useState('');
  const [presenterName, setPresenterName] = useState('');
  const [presenterEmail, setPresenterEmail] = useState('');
  const [groupOfPoster, setGroupOfPoster] = useState({});
  const [groupsOfEvent, setGroupsOfEvent] = useState([]);
  const [eventId, setEventId] = useState('');
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [areGroupsOfEventLoading, setAreGroupsOfEventLoading] = useState(false);

  const { posterId } = useParams();

  const navigate = useNavigate();
  const getPoster = async () => {
    try {
      const { data } = await axios.get(`/api/posters/get-poster-by-id/${posterId}`);
      console.log(data);
      setTitle(data.title);
      setPosterIndex(data.index);
      setPresenterName(data.presenterName);
      setPresenterEmail(data.presenterEmail);
      setGroupOfPoster(data.group);
      setEventId(data.event._id);
      getGroupsOfEvent(data.event._id);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setPosterIsLoading(false);
  };

  const getGroupsOfEvent = async (internalEventId) => {
    setAreGroupsOfEventLoading(true);
    try {
      const { data } = await axios.get(`/api/groups/get-groups-of-event/${internalEventId}`);
      setGroupsOfEvent(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setAreGroupsOfEventLoading(false);
  };

  const updatePoster = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    try {
      const { data } = await axios.put(`/api/posters/update/${posterId}`, {
        title,
        index: posterIndex,
        presenterName,
        presenterEmail,
        group: groupOfPoster._id,
      });
      toast.success('Poster title updated successfully');
      navigate(`/events/${eventId}`);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }

    setIsRequestLoading(false);
  };

  const handleGroupChange = async (e) => {
    setGroupOfPoster(groupsOfEvent.find((group) => group._id === e.target.value));
  };

  useEffect(() => {
    getPoster();
  }, []);

  if (posterIsLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <Link to={`/events/${eventId}?tab=4`}>
        <Button>Back to event</Button>
      </Link>
      <h1>Poster Page</h1>

      <form onSubmit={updatePoster}>
        <Grid container spacing={3} direction='column' textAlign={'center'}>
          <Grid item>
            <TextField
              id='index'
              name='index'
              label='Index'
              type='number'
              value={posterIndex}
              required
              fullWidth
              onChange={(e) => setPosterIndex(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              multiline
              id='Title'
              name='Title'
              label='Title'
              type='text'
              value={title}
              required
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              id='Presenter Name'
              name='presenterName'
              label='Presenter Name'
              type='text'
              value={presenterName}
              // required
              fullWidth
              onChange={(e) => setPresenterName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              id='Presenter Email'
              name='presenter Email'
              label='Presenter Email'
              type='text'
              value={presenterEmail}
              // required
              fullWidth
              onChange={(e) => setPresenterEmail(e.target.value)}
            />
          </Grid>
          {!areGroupsOfEventLoading ? (
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Group</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={groupOfPoster?._id}
                  label='Group'
                  onChange={handleGroupChange}
                >
                  {groupsOfEvent.map((group) => (
                    <MenuItem key={group._id} value={group._id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant='caption' display='block' gutterBottom>
                By changing group of poster you will delete all scores of poster and create new scores for new group.
              </Typography>
            </Grid>
          ) : (
            <h4>Loading Groups...</h4>
          )}
          <Grid item textAlign={'center'} justifyContent={'space-evenly'} display='flex'>
            <LoadingButton loading={isRequestLoading} color='primary' type='submit' variant='contained' size='small'>
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EditPoster;
