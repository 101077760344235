import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const CreateDemoEventButton = ({ competitionNumber }) => {
  const navigate = useNavigate();
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const demoButtonClicked = () => {
    console.log('Someone clicked on the demo button!');
    ReactGA.event({
      category: 'Demo button category',
      action: 'Demo button clicked',
      label: 'Demo button clicked',
    });
  };

  const createDemoEvent = async () => {
    setIsRequestLoading(true);
    try {
      const { data } = await axios.post(`/api/events/create-demo-event/${competitionNumber}`);
      toast.success(`Demo event created`);
      const eventId = data.eventId;
      navigate(`/client-view/${eventId}`);
      demoButtonClicked();
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
    setIsRequestLoading(false);
  };

  return (
    <LoadingButton loading={isRequestLoading} onClick={createDemoEvent} variant='contained' color='secondary'>
      Create demo event
    </LoadingButton>
  );
};

export default CreateDemoEventButton;
