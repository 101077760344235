import React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import LoadingButton from '@mui/lab/LoadingButton';

const CreateGroup = ({ eventId, onSuccess = () => console.log('No on success was passed!'), closeModal }) => {
  const [groupName, setGroupName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post(`/api/groups/create/${eventId}`, { name: groupName.trim() });
      setSuccess(data.message);
      setLoading(false);
      setGroupName('');
      onSuccess();
      //   setSuccess(data.message);
      toast.success('Group created successfully');
    } catch (error) {
      console.log(error.response.data.error_message);
      toast.error(error.response.data.error_message);
      //   setError(error.response.data.error_message);
      setLoading(false);
    }
  };

  return (
    <div className='padding1 margin1'>
      <form onSubmit={submitHandler}>
        <Grid container spacing={3} direction='column' textAlign={'center'}>
          <h1>Create Group</h1>
          <Grid item>
            <TextField
              id='name'
              name='name'
              label='Group Name'
              type='text'
              value={groupName}
              required
              fullWidth
              onChange={(e) => setGroupName(e.target.value)}
            />
          </Grid>
          <Grid item>
            {error && <p className='error-message'>{error}</p>}
            {success && <p className='success-message'>{success}</p>}
          </Grid>
          <Grid item className='buttons-container'>
            <Button onClick={closeModal}>Cancel</Button>
            <LoadingButton loading={loading} type='submit' variant='contained' disabled={groupName.trim().length === 0}>
              Create group
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreateGroup;
