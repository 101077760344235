import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ExcelToJsonComponent from '../ui/ExcelToJSONComponent';
import { Button } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import PosterRow from '../poster/PosterRow';
import DownloadImportPostersTemplate from '../ui/DownloadImportPostersTemplate';

const ImportPostersToEvent = () => {
  const [postersToBeImported, setPostersToBeImported] = useState([]);
  const { eventId } = useParams();
  const [posters, setPosters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const getPostersOfEvent = async () => {
    try {
      const { data } = await axios.get(`/api/posters/get-posters-of-event/${eventId}`);
      setPosters(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPostersOfEvent();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const importPosters = async () => {
    try {
      const { data } = await axios.post(`/api/posters/import/${eventId}`, postersToBeImported);
      toast.success(`${data.length} posters imported`);
      navigate(`/events/${eventId}`);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <div>
      <Link to={`/events/${eventId}?tab=4`}>
        <Button>Back to event</Button>
      </Link>
      <h2>Posters</h2>
      <p>Number of posters: {posters.length}</p>
      <div className='col'>
        {posters.map((poster) => (
          <div key={poster._id} className='row'>
            <PosterRow poster={poster} onSuccessfulDelete={getPostersOfEvent} />
          </div>
        ))}
      </div>
      <div className='row'>
        <h1>Import Posters</h1>
        <div className='actions-container'>
          <DownloadImportPostersTemplate />
        </div>
      </div>
      {postersToBeImported.length > 0 ? (
        <div className='col'>
          <div className='row'>
            <p>Poster Index</p>
            <p>Title</p>
            <p>Presenter Name</p>
            <p>Presenter Email</p>
            <p>Group</p>
          </div>
          {postersToBeImported.map((poster, index) => (
            <div key={index} className='row'>
              <p>{poster[`Poster Index`]}</p>
              <p>{poster[`Title`]}</p>
              <p>{poster[`Presenter Name`]}</p>
              <p>{poster[`Presenter Email`]}</p>
              <p>{poster[`Group`]}</p>
            </div>
          ))}
          <Button variant='contained' onClick={importPosters}>
            Import Posters
          </Button>
          <Button variant='text' onClick={() => setPostersToBeImported([])}>
            Clear
          </Button>
        </div>
      ) : (
        <ExcelToJsonComponent setPostersToBeImported={setPostersToBeImported} />
      )}
    </div>
  );
};

export default ImportPostersToEvent;
