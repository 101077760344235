import React, { useState, useEffect } from 'react';
import axios from 'axios';

// import exportFromJSON from 'export-from-json';
// const exportType = exportFromJSON.types.xls;
import { Button } from '@mui/material';
import exportJSONToExcel from '../../utils/exportJSONToExcel';
const fileName = 'All Scores of event';

const ExportAllScoresButton = ({ eventId }) => {
  const [scores, setScores] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const getScoresOfEvent = async () => {
    try {
      const { data } = await axios.get(`/api/scores/get-all-scores-of-event/${eventId}`);
      setScores(data);
      setIsLoading(false);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getScoresOfEvent();
  }, [eventId]);

  const formatScoresForExport = (score) => {
    const obj = {};
    obj['Score ID'] = score._id;
    obj['Judge name'] = score.judge.name;
    obj['Poster title'] = score.poster.title;

    score.event.scoringQuestions.forEach((question, index) => {
      obj[question] = score.answers[index];
    });

    obj['Average score of judge'] = score.answers.reduce((a, b) => a + b, 0) / score.answers.length;

    return obj;
  };

  const exportPosters = async () => {
    try {
      const a = await getScoresOfEvent();
      const data = a.map((s) => formatScoresForExport(s));

      // WARNING!!! keep names as is
      exportJSONToExcel(data, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  if (!eventId) return <Button color='error'>Cannot export scores: Event ID is missing</Button>;

  return (
    <div className='col margin1'>
      <Button variant='contained' onClick={exportPosters}>
        {/* Export all scores ({isLoading ? 'Loading...' : scores.length}) */}
        Export all scores
      </Button>
    </div>
  );
};

export default ExportAllScoresButton;
