import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthenticationContext } from '../../context/AuthenticationContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuthenticationContext();

  return isAuthenticated ? children : <Navigate to={'/login'} />;
};

const NonAuthRoute = ({ children }) => {
  const { isAuthenticated } = useAuthenticationContext();

  return !isAuthenticated ? children : <Navigate to={'/events'} />;
};

export { ProtectedRoute, NonAuthRoute };
