import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { Grid, TextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const EditJudge = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [eventId, setEventId] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const { judgeId } = useParams();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    try {
      const { data } = await axios.post(`/api/judges/update/${judgeId}`, { name, email });
      setSuccess(data.message);
      toast.success('Judge updated successfully');
      navigate(`/events/${eventId}`);
    } catch (error) {
      console.log(error.response.data.error_message);
      toast.error(error.response.data.error_message);
      setError(error.response.message);
    }
    setIsRequestLoading(false);
  };

  const getJudge = async () => {
    try {
      const { data } = await axios.get(`/api/judges/${judgeId}`);
      setName(data.name);
      setEmail(data.email);
      setEventId(data.event._id);
      setLoading(false);
      console.log(data);
    } catch (error) {
      console.log(error.response.data.error_message);
      toast.error(error.response.data.error_message);
    }
  };

  useEffect(() => {
    getJudge();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='shadowed padding1'>
      <Link to={`/events/${eventId}?tab=2`}>
        <Button>Back to event</Button>
      </Link>
      {error && <p>{error}</p>}
      {success && <p>{success}</p>}
      <form onSubmit={submitHandler}>
        <Grid container spacing={3} direction='column' textAlign={'center'}>
          <h1>Edit Judge</h1>
          <Grid item>
            <TextField id='name' name='name' label='name' type='text' value={name} required fullWidth onChange={(e) => setName(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField
              id='email'
              email='email'
              label='email'
              type='email'
              value={email}
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item textAlign={'center'}>
            <LoadingButton loading={isRequestLoading} type='submit' variant='contained'>
              Save Judge
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EditJudge;
