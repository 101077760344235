import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useEventContext } from '../../context/EventContext';
import DeleteEventButton from './DeleteEventButton';

const EventCard = ({ event, onDeleteSuccess }) => {
  const { setActiveEventId } = useEventContext();
  return (
    <div className='p-4 max-w-sm mx-auto bg-white rounded-lg border border-gray-200 shadow-md'>
      <h1 className='mb-2 text-xl font-bold tracking-tight text-gray-900'>{event.name}</h1>
      <p className='mb-3 font-normal text-gray-700'>{event.description}</p>
      <div className='flex justify-between items-center'>
        <Link to={`/events/${event._id}`}>
          <Button
            onClick={() => setActiveEventId(event._id)}
            variant='contained'
            className='text-sm bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
          >
            View Event
          </Button>
        </Link>
        <DeleteEventButton eventId={event._id} onDeleteSuccess={onDeleteSuccess} />
      </div>
    </div>
  );
};

export default EventCard;
