import * as React from 'react';
import { useEffect } from 'react'; // Import useEffect
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SwipeableTemporaryDrawer from './SwipeableTemporaryDrawer';
import { useAuthenticationContext } from '../../context/AuthenticationContext';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation

export default function ButtonAppBar() {
  const { isAuthenticated, logout } = useAuthenticationContext();
  const location = useLocation(); // Use useLocation hook to get the current location object

  const isLandingPage = location.pathname === '/'; // Use location.pathname

  if (isLandingPage) {
    return null;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 1 }} width={'100%'}>
      <AppBar position='static'>
        <Toolbar>
          {isAuthenticated && <SwipeableTemporaryDrawer />}
          <Link to='/'>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              InstaJudge
            </Typography>
          </Link>
          {isAuthenticated && (
            <Button color='inherit' onClick={logout}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
