import React, { useState, useEffect } from 'react';
import axios from 'axios';

// import exportFromJSON from 'export-from-json';
// const exportType = exportFromJSON.types.xls;
import { Button } from '@mui/material';
import exportJSONToExcel from '../../utils/exportJSONToExcel';
const fileName = 'Winners of event';

const ExportGroupWinnersButton = ({ eventId, winners, groups }) => {
  const [scores, setScores] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const getScoresOfEvent = async () => {
    try {
      const { data } = await axios.get(`/api/scores/get-all-scores-of-event/${eventId}`);
      setScores(data);
      setIsLoading(false);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getScoresOfEvent();
  }, [eventId]);

  const exportPosters = async () => {
    try {
      const data = [];
      // get the maximum possible length of winning posters columns
      let len = Object.values(winners).reduce((max, group) => {
        return group.posters.length > max ? group.posters.length : max;
      }, 0);

      for (const groupId in winners) {
        let obj = { 'Group Name': groups.filter((group) => group._id === groupId)[0].name, 'Total Winning Posters': winners[groupId].posters.length };

        for (let i = 0; i < len; i++) {
          obj[`Winning Poster Title ${i + 1}`] = '-';
        }

        // create multiple columns if posters are tied
        // for (const i in winners[groupId].posters) {
        for (let i = 0; i < winners[groupId].posters.length; i++) {
          obj[`Winning Poster Title ${i + 1}`] = winners[groupId].posters[i].posterTitle;
        }
        data.push(obj);
      }

      // // WARNING!!! keep names as is
      console.log(data, 'data');
      exportJSONToExcel(data, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  if (!eventId) return <Button color='error'>Cannot export scores: Event ID is missing</Button>;

  return (
    <div className='col margin1'>
      <Button variant='contained' onClick={exportPosters}>
        Export Group Winners {isLoading ? 'Loading...' : ''}
      </Button>
    </div>
  );
};

export default ExportGroupWinnersButton;
