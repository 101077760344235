import React from 'react';
import { Button, Tooltip } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

const JudgeRow = ({ judge, onSuccessfulDelete }) => {
  const [isDeleteJudgeRequestLoading, setIsDeleteJudgeRequestLoading] = useState(false);

  const deleteJudge = async (judgeId) => {
    setIsDeleteJudgeRequestLoading(true);
    try {
      await axios.delete(`/api/judges/delete/${judgeId}`);
      toast.success('Judge deleted');
      onSuccessfulDelete();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsDeleteJudgeRequestLoading(false);
  };

  return (
    <>
      <h3>{judge.name}</h3>
      <p>{judge.email}</p>
      <div className='actions-container'>
        <Link to={`/judge-view/${judge._id}/all`}>
          <Button variant='contained' color='primary' size='small'>
            Experience
          </Button>
        </Link>
        <Link to={`/judges/${judge._id}`}>
          <Button variant='contained' color='warning' size='small'>
            Edit judge
          </Button>
        </Link>
        <Tooltip title='Working on it' placement='top' arrow>
          <LoadingButton loading={false} variant='contained' color='tetriary' onClick={() => console.log('send email to judge')} size='small'>
            Inform judge
          </LoadingButton>
        </Tooltip>
        <LoadingButton loading={isDeleteJudgeRequestLoading} variant='contained' color='error' onClick={() => deleteJudge(judge._id)} size='small'>
          Delete Judge
        </LoadingButton>
      </div>
    </>
  );
};

export default JudgeRow;
