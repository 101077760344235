import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import JudgeRow from './JudgeRow';
import AddJudgeButton from './AddJudgeButton';
import { TextField, Button, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';
import GridLoader from '../ui/GridLoader';
import { H2 } from '../ui/HTags';

const AllJudges = ({ eventId }) => {
  const [judges, setJudges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getAllJudgesOfEvent = async () => {
    try {
      const { data } = await axios.get(`/api/judges/get-judges-of-event/${eventId}`);
      setJudges(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllJudgesOfEvent();
  }, []);

  if (isLoading) {
    return <GridLoader />;
  }

  const filterByName = (judge) => judge.name.toLowerCase().includes(searchText.toLowerCase());
  const filterByEmail = (judge) => judge.email.toLowerCase().includes(searchText.toLowerCase());
  const filteredJudges = judges.filter((judge) => filterByName(judge) || filterByEmail(judge));
  const totalPages = Math.ceil(filteredJudges.length / itemsPerPage);
  const paginatedJudges = filteredJudges.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      <div className='row'>
        <H2 className='text-xl'>Judges ({judges.length})</H2>
        <div className='actions-container'>
          <Link to={`/events/print-qr-codes-for-judges/${eventId}`}>
            <Button color='success' size='small'>
              Print QR codes for judges
            </Button>
          </Link>
          <AddJudgeButton eventId={eventId} onSuccess={getAllJudgesOfEvent} />
        </div>
      </div>
      {judges.length === 0 && <p>No judges yet</p>}
      <TextField
        id='search'
        search='search'
        label='Search by name | email '
        type='text'
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        size='small'
        fullWidth
      />
      <div className='col'>
        {paginatedJudges.map((judge) => (
          <div key={judge._id} className='row'>
            <JudgeRow judge={judge} onSuccessfulDelete={getAllJudgesOfEvent} />
          </div>
        ))}
      </div>
      <div className='pagination-container'>
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color='primary' />
      </div>
    </>
  );
};

export default AllJudges;
