import React, { useEffect, useState } from 'react';
import { TextField, Pagination } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import GroupRow from './GroupRow';
import AddGroupButton from './AddGroupButton';
import GridLoader from '../ui/GridLoader';

const AllGroups = ({ eventId }) => {
  const [groupsOfEvent, setGroupsOfEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getGroupsOfEvent = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/api/groups/get-groups-of-event/${eventId}`);
      setGroupsOfEvent(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getGroupsOfEvent();
  }, []);

  if (isLoading) {
    return <GridLoader />;
  }

  const filteredGroups = groupsOfEvent.filter((group) => group.name.toLowerCase().includes(searchText.toLowerCase()));
  const totalPages = Math.ceil(filteredGroups.length / itemsPerPage);
  const paginatedGroups = filteredGroups.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      {groupsOfEvent.length === 0 && <p>No groups yet</p>}
      <div className='row'>
        <h2>Groups ({groupsOfEvent.length})</h2>
        <div className='actions-container'>
          <AddGroupButton eventId={eventId} onSuccess={getGroupsOfEvent} />
        </div>
      </div>
      <TextField
        id='search'
        search='search'
        label='Search by group name '
        type='text'
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        size='small'
        fullWidth
      />
      <div className='col'>
        {paginatedGroups.map((group) => (
          <div key={group._id} className='row'>
            <GroupRow group={group} eventId={eventId} onSuccessfulDelete={getGroupsOfEvent} />
          </div>
        ))}
      </div>
      <div className='pagination-container'>
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color='primary' />
      </div>
    </>
  );
};

export default AllGroups;
