import React from 'react';
import { Button, Tooltip } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import PeopleIcon from '@mui/icons-material/People';

const GroupRow = ({ group, onSuccessfulDelete, eventId }) => {
  const [isDeleteGroupRequestLoading, setIsDeleteGroupRequestLoading] = useState(false);

  const deleteGroup = async (groupId) => {
    setIsDeleteGroupRequestLoading(true);
    try {
      await axios.delete(`/api/groups/delete/${groupId}`);
      toast.success('Group deleted');
      onSuccessfulDelete();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsDeleteGroupRequestLoading(false);
  };

  return (
    <>
      <h3>{group.name}</h3>
      <p>Number of judges: {group.judges.length}</p>
      {group.judges.length === 0 && (
        <Tooltip title='This group has no judges assigned to it' placement='right' arrow>
          <div>
            <WarningIcon color='error' />
          </div>
        </Tooltip>
      )}
      <div className='actions-container'>
        <Link to={`/start-judging-group/${eventId}/${group._id}`} target='_blank'>
          <Button variant='contained' color='primary' size='small'>
            Score group
          </Button>
        </Link>
        <Link to={`/groups/${group._id}`}>
          <Button variant='contained' color='warning' size='small'>
            Edit Group
          </Button>
        </Link>
        <LoadingButton variant='contained' color='error' size='small' onClick={() => deleteGroup(group._id)} loading={isDeleteGroupRequestLoading}>
          Delete Group
        </LoadingButton>
      </div>
      <div className='row' style={{ paddingBlock: '0', paddingInline: '1em' }}>
        <PeopleIcon />({group.judges.length})
        {group.judges.map((judge) => (
          <div key={judge._id}>
            <Link to={`/judges/${judge._id}`}>{judge.name}</Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default GroupRow;
