import { Skeleton } from '@mui/material';
import React from 'react';

const GridLoader = () => {
  return (
    <div className='col'>
      <div className='row'>
        <Skeleton variant='text' sx={{ fontSize: '2rem' }} height={50} width={100} animation='wave' />
        <Skeleton variant='text' sx={{ fontSize: '2rem', marginLeft: 'auto' }} height={50} width={100} animation='wave' />
      </div>
      <Skeleton variant='text' sx={{ fontSize: '2rem' }} height={70} animation='wave' />
      <Skeleton variant='text' sx={{ fontSize: '2rem' }} height={100} animation='wave' />
      <Skeleton variant='text' sx={{ fontSize: '2rem' }} height={100} animation='wave' />
      <Skeleton variant='text' sx={{ fontSize: '2rem' }} height={100} animation='wave' />
      <Skeleton variant='text' sx={{ fontSize: '2rem' }} height={100} animation='wave' />
    </div>
  );
};

export default GridLoader;
