import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Button, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EventLabels from './EventLabels';
import EventScoringMode from './EventScoringMode';
import EventSettings from './EventSettings';

const EventDetails = ({ eventId }) => {
  const [showEditEventDetails, setShowEditEventDetails] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [newName, setNewName] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const getEvent = async () => {
    try {
      const { data } = await axios.get(`/api/events/get-by-id/${eventId}`);
      setName(data.name);
      setNewName(data.name);
      setDescription(data.description);
      setNewDescription(data.description);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    // setEventIsLoading(false);
  };

  useEffect(() => {
    getEvent();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    try {
      const { data } = await axios.put(`/api/events/update/${eventId}`, { name: newName, description: newDescription });
      toast.success('Event updated successfully');
      setName(newName);
      setDescription(newDescription);
      setShowEditEventDetails(false);
    } catch (error) {
      console.log(error.response.data.error_message);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  const handleCancel = () => {
    setShowEditEventDetails(false);
    setNewName(name);
    setNewDescription(description);
  };

  return showEditEventDetails ? (
    <form onSubmit={submitHandler} className='shadowed padding1 margin1'>
      <Grid container spacing={3} direction='column' textAlign={'center'}>
        <Grid item>
          <h1>Edit event details</h1>
        </Grid>
        <Grid item>
          <TextField id='name' name='name' label='name' type='text' value={newName} required fullWidth onChange={(e) => setNewName(e.target.value)} />
        </Grid>
        <Grid item>
          <TextField
            multiline
            id='description'
            name='description'
            label='description'
            type='text'
            value={newDescription}
            required
            fullWidth
            onChange={(e) => setNewDescription(e.target.value)}
          />
        </Grid>

        <Grid item textAlign={'center'} justifyContent={'space-evenly'} display='flex'>
          <Button loading={isRequestLoading} size='small' onClick={handleCancel}>
            Cancel
          </Button>
          <LoadingButton loading={isRequestLoading} color='primary' type='submit' variant='contained' size='small'>
            Save details
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  ) : (
    <>
      <div>
        <h1>{name}</h1>
        <p>{description}</p>

        <Button onClick={() => setShowEditEventDetails(true)} size='small' variant='contained' color='warning'>
          Edit event details
        </Button>
      </div>
      <EventLabels eventId={eventId} />
      <EventScoringMode eventId={eventId} />
      <EventSettings eventId={eventId} />
    </>
  );
};

export default EventDetails;
