import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

const testimonials = [
  {
    quote: 'It worked great! We were able to quickly review with judges in real time and the judges liked the QR codes and phone scoring.',
    name: 'Taylor Baumgartner',
    position: 'Meeting Manager, Plastic Surgery Research Council',
  },
  {
    quote: 'InstaJudge and its user-friendly interface ensures quick and accurate, in-the-moment feedback, revolutionizing academic assessments.',
    name: 'Pavlos Efraimidis',
    position: 'Professor, ECE, Democritus University of Thrace and affiliated member of Athena RC',
  },
];

const TestimonialsCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const handlePrevClick = () => {
    if (animating) return;
    setAnimating(true);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    if (animating) return;
    setAnimating(true);
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextClick(),
    onSwipedRight: () => handlePrevClick(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <section className='bg-mint-deep-green hero-section' {...handlers}>
      <div className='max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6 relative'>
        <div className='overflow-hidden relative max-w-[700px] mx-auto'>
          <div
            className='flex transition-transform duration-1000 ease-in-out max-w-[90vw]'
            style={{ transform: `translateX(-${currentIndex * 100}%)`, marginInline: 'auto' }}
            onTransitionEnd={() => setAnimating(false)}
          >
            {testimonials.map((testimonial, index) => (
              <figure
                key={index}
                className={`w-full flex-shrink-0 transform testimonial-glass-container transition-opacity duration-1000 ease-in-out ${
                  index === currentIndex ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <svg className='h-12 mx-auto mb-3 text-gray-400' viewBox='0 0 24 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z'
                    fill='currentColor'
                  />
                </svg>
                <blockquote>
                  <p className='text-xl font-medium text-gray-900 md:text-2xl'>"{testimonial.quote}"</p>
                </blockquote>
                <figcaption className='flex items-center justify-center mt-6 space-x-3'>
                  <div className='flex items-center divide-x-2 divide-gray-500'>
                    <div className='pr-3 font-medium text-gray-900'>{testimonial.name}</div>
                    <div className='pl-3 text-sm font-light text-gray-500'>{testimonial.position}</div>
                  </div>
                </figcaption>
              </figure>
            ))}
          </div>
        </div>
        {/* <div className='flex justify-between mt-8 lg:hidden'>
          <button className='px-4 py-2 font-bold text-white bg-gray-800 rounded hover:bg-gray-700' onClick={handlePrevClick}>
            Previous
          </button>
          <button className='px-4 py-2 font-bold text-white bg-gray-800 rounded hover:bg-gray-700' onClick={handleNextClick}>
            Next
          </button>
        </div> */}
        {/* <div className='hidden lg:flex justify-between absolute inset-y-0 left-0 right-0'>
          <button
            className='px-4 py-2 font-bold text-white bg-gray-800 rounded-full hover:bg-gray-700 self-center'
            onClick={handlePrevClick}
            style={{ marginLeft: '1rem' }}
          >
            &larr;
          </button>
          <button
            className='px-4 py-2 font-bold text-white bg-gray-800 rounded-full hover:bg-gray-700 self-center'
            onClick={handleNextClick}
            style={{ marginRight: '1rem' }}
          >
            &rarr;
          </button>
        </div> */}
        <div className='flex justify-center mt-4'>
          {testimonials.map((_, index) => (
            <span
              key={index}
              className={`h-2 w-2 mx-1 rounded-full ${index === currentIndex ? 'bg-mint-light-green' : 'bg-gray-400'}`}
              onClick={() => {
                if (animating) return;
                setAnimating(true);
                setCurrentIndex(index);
              }}
            ></span>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsCarousel;
